import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssBaseline as CssBase2 } from "@mui/material";
import Box from "@mui/joy/Box";
import Header from "./Header";
import ColorSchemeToggle from "../../utils/ColorSchemaToggle";
import customTheme from "../../utils/theme";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser, setCredentials } from "../auth/authSlice";
import {
  getFromCookies,
  removeUser,
} from "../../app/utils";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "./Alert";
import {
  closeNotify,
  openNotify,
  selectNotify,
} from "../../features/notifications/notifySlice";


export default function Layout(props: { children: React.ReactNode }) {
  const user_data = useSelector(selectCurrentUser);
  const currentNotification = useSelector(selectNotify);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user_data.login) {
      const login = getFromCookies("login");
      const password = getFromCookies("password");

      if (login && password) {
        dispatch(
          setCredentials({
            login,
            password,
          })
        );
      } else {
        removeUser();
        navigate("/login");
      }
    }
  }, [dispatch, navigate, user_data]);

  return (
    <CssVarsProvider disableTransitionOnChange theme={customTheme}>
      <GlobalStyles
        styles={{
          "[data-feather], .feather": {
            color: "var(--Icon-color)",
            margin: "var(--Icon-margin)",
            fontSize: "var(--Icon-fontSize, 20px)",
            width: "1em",
            height: "1em",
          },
        }}
      />
      <CssBaseline />
      <CssBase2 />

      <Alert
        open={currentNotification.open}
        setOpen={(state: any) => {
          if (!state) {
            dispatch(closeNotify());
          } else {
            dispatch(openNotify());
          }
        }}
        color={currentNotification.type}
        duration={currentNotification.duration}
        text={currentNotification.message}
      />

      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Header />

        <Box
          component="main"
          className="MainContent"
          sx={(theme: any) => ({
            px: {
              xs: 2,
              md: 6,
            },
            pt: {
              xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
              sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
              md: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            ml: "10px",
          })}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ColorSchemeToggle
              sx={{ ml: "auto", display: { xs: "none", md: "inline-flex" } }}
            />
          </Box>
          {props.children}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
