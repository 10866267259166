import React from "react";
import { Table, Sheet, Button } from "@mui/joy";
import {
  AllowanceData,
  formatWithDecimals,
  getNetworkName,
} from "../../../app/utils";

interface AllowanceTableProps {
  allowances: AllowanceData[];
  loadingAllowances?: AllowanceData[];
  onMakeTransfer: (allowance: AllowanceData) => void;
  onSelect: (allowance: AllowanceData) => void;
}

const AllowanceTable: React.FC<AllowanceTableProps> = ({
  allowances,
  loadingAllowances,
  onMakeTransfer,
  onSelect,
}) => {
  return (
    <Sheet>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Balance at Creation</th>
            <th>Current Allowance</th>
            <th>Asset</th>
            <th>Address</th>
            <th>Current Balance</th>
            <th>Network</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allowances?.map((allowance) => (
            <tr
              key={allowance.AvailableAllowance.ID}
              style={{
                opacity: loadingAllowances?.includes(allowance) ? 0.5 : 1,
              }}
            >
              <td>
                <input type="checkbox" onChange={() => onSelect(allowance)} />
              </td>
              <td>{allowance.AvailableAllowance.ID}</td>
              <td>
                {formatWithDecimals(
                  allowance.AvailableAllowance.BalanceAtCreated,
                  allowance.Asset.Decimal
                )}{" "}
                {allowance.Asset.Name}
              </td>
              <td>
                {formatWithDecimals(
                  allowance.AvailableAllowance.CurrentAllowance,
                  allowance.Asset.Decimal
                )}{" "}
                {allowance.Asset.Name}
              </td>
              <td>{allowance.Asset.Name}</td>
              <td
                style={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  wordBreak: "break-all",
                  //   textOverflow: "ellipsis",
                  //   whiteSpace: "nowrap",
                }}
              >
                {allowance.AvailableAllowance.Address}
              </td>
              <td>
                {formatWithDecimals(
                  allowance.AvailableAllowance.CurrentBalance,
                  allowance.Asset.Decimal
                )}{" "}
                {allowance.Asset.Name}
              </td>
              <td>{getNetworkName(allowance.AvailableAllowance.NetworkId)}</td>
              <td>
                <Button onClick={() => onMakeTransfer(allowance)}>
                  Make transfer
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
};

export default AllowanceTable;
