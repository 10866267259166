import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

type AuthState = {
	login: string;
	password: string;
};

const slice = createSlice({
	name: 'auth',
	initialState: { login: '', password: '' } as AuthState,
	reducers: {
		setCredentials: (state, { payload: { login, password } }: PayloadAction<{ 
			login: string;
			password: string;
		 }>) => {
			state.login = login;
			state.password = password;
		},
		logout: () => {
			return { login: '', password: '' };
		},
	},
});

export const { setCredentials, logout } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth;
