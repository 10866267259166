// import { useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../app/services/auth';
import ColorSchemeToggle from '../../utils/ColorSchemaToggle';
import customTheme from '../../utils/theme';
import { setCredentials } from './authSlice';
import { Alert } from '../components/Alert';

interface FormElements extends HTMLFormControlsCollection {
	login: HTMLInputElement;
	password: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
	readonly elements: FormElements;
}

export default function Login() {
	const [login, { isLoading, isError }] = useLoginMutation();
	const [open, setOpen] = useState(false);
	const [textError, setTextError] = useState('');
	const [textColor, setTextColor] = useState('danger');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isError) {
			setOpen(true);
			setTextError('Login or password is incorrect');
		}
	}, [isError]);

	return (
		<CssVarsProvider defaultMode="dark" disableTransitionOnChange theme={customTheme}>
			<CssBaseline />
			<GlobalStyles
				styles={{
					':root': {
						'--Transition-duration': '0.4s',
					},
				}}
			/>
			<Alert open={open} setOpen={setOpen} text={textError} color={textColor} />
			<Box
				sx={(theme: any) => ({
					width: '100vw',
					transition: 'width var(--Transition-duration)',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					position: 'relative',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backdropFilter: 'blur(4px)',
					backgroundColor: 'rgba(255 255 255 / 0.6)',
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'rgba(19 19 24 / 0.4)',
					},
				})}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100dvh',
						width: '100%',
						maxWidth: '100%',
						px: 2,
					}}>
					<Box
						component="header"
						sx={{
							py: 3,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Typography
							fontWeight="lg"
							startDecorator={
								<Box
									component="span"
									sx={{
										width: 24,
										height: 24,
										background: (theme: { vars: { palette: { primary: { solidBg: any; softBg: any } } } }) => `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
										borderRadius: '50%',
										boxShadow: (theme: { shadow: { md: any } }) => theme.shadow.md,
										'--joy-shadowChannel': (theme: { vars: { palette: { primary: { mainChannel: any } } } }) => theme.vars.palette.primary.mainChannel,
									}}
								/>
							}>
							ADMIN
						</Typography>
						<ColorSchemeToggle />
					</Box>
					<Box
						component="main"
						sx={{
							my: 'auto',
							py: 2,
							pb: 5,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							width: 400,
							maxWidth: '100%',
							mx: 'auto',
							borderRadius: 'sm',
							'& form': {
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							},
							[`& .${formLabelClasses.asterisk}`]: {
								visibility: 'hidden',
							},
						}}>
						<div>
							<Typography component="h2" fontSize="xl2" fontWeight="lg">
								Welcome!
							</Typography>
						</div>
						<form
							onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
								event.preventDefault();
								const formElements = event.currentTarget.elements;
								const data = {
									login: formElements.login.value,
									password: formElements.password.value,
								};

								const user = await login({
									login: data.login,
									password: data.password,
								}).unwrap();
								dispatch(setCredentials({
									login: data.login,
									password: data.login,
								}));

								navigate('/');
							}}>
							<FormControl required>
								<FormLabel>Login</FormLabel>
								<Input placeholder="Your login" type="text" name="login" />
							</FormControl>
							<FormControl required>
								<FormLabel>Password</FormLabel>
								<Input placeholder="•••••••" type="password" name="password" />
							</FormControl>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}></Box>
							<Button type="submit" fullWidth disabled={isLoading}>
								{isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : 'Login'}
							</Button>
						</form>
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
