import { Route, Routes } from 'react-router-dom';
import Login from './features/auth/Login';
import { NotFound } from './pages/NotFound';
import Main from './pages/Main';

function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/" element={<Main />} index />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	);
}

export default App;
