import {
  Box,
  Typography,
  Input,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
} from "@mui/joy";
import Layout from "../features/components/Layout";
import { useEffect, useState } from "react";
import {
  useGetAvailableAllowanceMutation,
  useMakeTransferMutation,
} from "../app/services/admin";
import AllowanceCard from "../features/components/ui/AllowanceCard";
import {
  AllowanceData,
  convertToDecimal,
  formatWithDecimals,
} from "../app/utils";
import AllowanceTable from "../features/components/ui/AllowanceTable";
import { ModalTemplate } from "../features/components/ui/Modal";
import { useDispatch } from "react-redux";
import { setNotify } from "../features/notifications/notifySlice";

export default function Main() {
  const [getAllowanceData, { data: allowanceData, isLoading, isSuccess }] =
    useGetAvailableAllowanceMutation();

  const [filteredData, setFilteredData] = useState<AllowanceData[]>([]);
  const [amountFilterFrom, setAmountFilterFrom] = useState<any>("");
  const [amountFilterTo, setAmountFilterTo] = useState<any>("");
  const [walletFilter, setWalletFilter] = useState<any>("");
  const [assetFilter, setAssetFilter] = useState<any>("");
  const [selectedWallets, setSelectedWallets] = useState<any>({});
  const [selectedAllowance, setSelectedAllowance] = useState<AllowanceData>();
  const [openModal, setOpenModal] = useState(false);
  const [loadingAllowances, setLoadingAllowances] = useState<AllowanceData[]>();

  const dispatch = useDispatch();

  const [
    makeTransfer,
    {
      data: transferData,
      isLoading: transferLoading,
      isSuccess: transferSuccess,
      isError: transferError,
      originalArgs: transferArgs,
    },
  ] = useMakeTransferMutation();

  useEffect(() => {
    if (transferSuccess) {
      dispatch(
        setNotify({
          message: "Transfer sended!",
          type: "success",
          duration: 2000,
        })
      );

      setOpenModal(false);
    }

    if (transferError) {
      dispatch(
        setNotify({
          message: "Transfer error!",
          type: "danger",
          duration: 2000,
        })
      );
    }
  }, [transferSuccess, transferError]);

  useEffect(() => {
    getAllowanceData();
  }, [getAllowanceData]);

  useEffect(() => {
    if (isSuccess && allowanceData) {
      filterData();
    }
  }, [
    isSuccess,
    allowanceData,
    walletFilter,
    assetFilter,
    amountFilterFrom,
    amountFilterTo,
  ]);

  const filterData = () => {
    let filtered = allowanceData.filter((item: any) => {
      const amount = formatWithDecimals(
        item.AvailableAllowance.CurrentBalance,
        item.Asset.Decimal
      );
      const asset = item.Asset.Name.toLowerCase();

      const wallet = item.AvailableAllowance.Address.toLowerCase();
      return (
        (!amountFilterFrom || amount >= parseInt(amountFilterFrom)) &&
        (!amountFilterTo || amount <= parseInt(amountFilterTo)) &&
        (!assetFilter || asset.includes(assetFilter.toLowerCase())) &&
        (!walletFilter || wallet.includes(walletFilter.toLowerCase()))
      );
    });
    setFilteredData(filtered);
  };

  const sendAllowances = async (data: AllowanceData[]) => {
    console.log("Sending allowances", data);

    const batch = 3;
    const delay = 3000;

    const revokeAllowance = async (allowance: AllowanceData) => {
      if (Number(allowance.AvailableAllowance.CurrentBalance) < 1) {
        return;
      }

      try {
        setLoadingAllowances((prev) => {
          return [...(prev || []), allowance];
        });

        const result = await makeTransfer({
          Amount:
            Number(allowance.AvailableAllowance.CurrentBalance) >
            Number(allowance.AvailableAllowance.CurrentAllowance)
              ? allowance.AvailableAllowance.CurrentAllowance
              : allowance.AvailableAllowance.CurrentBalance,
          AllowanceId: allowance.AvailableAllowance.ID,
        }).unwrap();

        setLoadingAllowances((prev) => {
          return prev?.filter(
            (item) =>
              item.AvailableAllowance.ID !== allowance.AvailableAllowance.ID
          );
        });
      } catch (error) {
        console.error("Error revoking allowance", allowance, error);

        setLoadingAllowances((prev) => {
          return prev?.filter(
            (item) =>
              item.AvailableAllowance.ID !== allowance.AvailableAllowance.ID
          );
        });
      }
    };

    data.forEach((allowance, index) => {
      setTimeout(() => {
        revokeAllowance(allowance);
      }, (index % batch) * delay);
    });
  };

  const handleRevokeAll = () => {
    console.log("Revoking all allowances");

    sendAllowances(allowanceData);
  };

  const handleRevokeFiltered = () => {
    console.log("Revoking filtered allowances", filteredData);

    sendAllowances(filteredData);
  };

  const handleRevokeSelected = () => {
    const selectedAddresses = Object.keys(selectedWallets).filter(
      (address) => selectedWallets[address]
    );
    console.log("Revoking selected allowances", selectedAddresses);

    const selectedData = allowanceData.filter((item: any) =>
      selectedAddresses.includes(item.AvailableAllowance.Address)
    );

    sendAllowances(selectedData);
  };

  const toggleWalletSelection = (allowance: AllowanceData) => {
    setSelectedWallets((prev: any) => ({
      ...prev,
      [allowance.AvailableAllowance.Address]:
        !prev[allowance.AvailableAllowance.Address],
    }));
  };

  const handleMakeTransfer = (allowance: AllowanceData) => {
    setSelectedAllowance(allowance);
    setOpenModal(true);
  };

  return (
    <Layout>
      <ModalTemplate
        open={openModal}
        setOpen={setOpenModal}
        title="Enter amount for transfer"
      >
        {selectedAllowance && (
          <form
            onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();

              const formElements = new FormData(event.currentTarget);
              const data = Object.fromEntries(formElements.entries());

              if (!selectedAllowance) {
                return;
              }

              if (
                Number(data?.Amount) >
                formatWithDecimals(
                  selectedAllowance.AvailableAllowance.CurrentAllowance,
                  selectedAllowance.Asset.Decimal
                )
              ) {
                dispatch(
                  setNotify({
                    message: "amount > current allowance",
                    type: "danger",
                    duration: 2000,
                  })
                );

                return;
              }

              if (
                Number(data?.Amount) >
                formatWithDecimals(
                  selectedAllowance.AvailableAllowance.CurrentBalance,
                  selectedAllowance.Asset.Decimal
                )
              ) {
                dispatch(
                  setNotify({
                    message: "amount > balance",
                    type: "danger",
                    duration: 2000,
                  })
                );

                return;
              }

              await makeTransfer({
                Amount: convertToDecimal(
                  String(data?.Amount),
                  selectedAllowance.Asset.Decimal
                ),
                AllowanceId: selectedAllowance.AvailableAllowance.ID,
              });
            }}
          >
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                name="Amount"
                type="number"
                required
                slotProps={{ input: { min: 0, step: "any" } }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                defaultValue={
                  Number(selectedAllowance?.AvailableAllowance.CurrentBalance) >
                  Number(selectedAllowance?.AvailableAllowance.CurrentAllowance)
                    ? formatWithDecimals(
                        selectedAllowance.AvailableAllowance.CurrentAllowance,
                        selectedAllowance.Asset.Decimal
                      )
                    : formatWithDecimals(
                        selectedAllowance.AvailableAllowance.CurrentBalance,
                        selectedAllowance.Asset.Decimal
                      )
                }
              />
            </FormControl>

            <Button
              type="submit"
              style={{
                width: "100%",
                marginTop: "10px",
              }}
              loading={transferLoading}
            >
              Make transfer
            </Button>
          </form>
        )}
      </ModalTemplate>

      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <form
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <FormControl>
            <FormLabel>Filter by amount from - to</FormLabel>
            <div
              style={{
                display: "flex",
                gap: 6,
              }}
            >
              <Input
                value={amountFilterFrom}
                onChange={(e) => setAmountFilterFrom(e.target.value)}
                type="number"
              />

              <Input
                value={amountFilterTo}
                onChange={(e) => setAmountFilterTo(e.target.value)}
                type="number"
              />
            </div>
          </FormControl>
          <FormControl>
            <FormLabel>Filter by wallet</FormLabel>
            <Input
              value={walletFilter}
              onChange={(e) => setWalletFilter(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Filter by Asset</FormLabel>
            <Input
              value={assetFilter}
              onChange={(e) => setAssetFilter(e.target.value)}
            />
          </FormControl>
        </form>
      </Box>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <Button onClick={handleRevokeAll} variant="outlined">
          Revoke All Allowances
        </Button>
        <Button onClick={handleRevokeFiltered} variant="outlined">
          Revoke Filtered Allowances
        </Button>
        <Button onClick={handleRevokeSelected} variant="outlined">
          Revoke Selected Allowances
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : isSuccess ? (
          <>
            <AllowanceTable
              allowances={filteredData}
              loadingAllowances={loadingAllowances}
              onMakeTransfer={handleMakeTransfer}
              onSelect={toggleWalletSelection}
            />
          </>
        ) : (
          <Typography color="danger">Error loading data</Typography>
        )}
      </Box>
    </Layout>
  );
}
