import JoySnackbar from '../components/JoySnackbar';
import * as Toast from '@radix-ui/react-toast';
import Box from '@mui/joy/Box';

export const Alert = (props: any) => (
	<Toast.Provider swipeDirection="up">
		<JoySnackbar open={props.open} onOpenChange={props.setOpen} color={props.color} variant="soft">
			{props.text}
		</JoySnackbar>
		<Box
			component={Toast.Viewport}
			sx={{
				'--viewport-padding': '25px',
				position: 'fixed',
				top: 0,
				left: '50%',
				transform: 'translateX(-50%)',
				display: 'flex',
				flexDirection: 'column',
				padding: 'var(--viewport-padding)',
				gap: '10px',
				width: 'auto',
				maxWidth: '100vw',
				margin: 0,
				listStyle: 'none',
				zIndex: 2147483647,
				outline: 'none',
			}}
		/>
	</Toast.Provider>
);
