import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/auth';
import { admin } from './services/admin';
import authReducer from '../features/auth/authSlice';
import notifyReducer from '../features/notifications/notifySlice';

export const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		[admin.reducerPath]: admin.reducer,
		auth: authReducer,
		notify: notifyReducer,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware).concat(admin.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
