import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

type NotifyState = {
    open: boolean;
    message: string;
    type: string;
    duration: number;
};

const slice = createSlice({
	name: 'notify',
	initialState: { 
        open: false,
        message: '',
        type: '',
        duration: 3000,
     } as NotifyState,
	reducers: {
        setNotify: (state, { payload: { message, type, duration = 3000 } }: PayloadAction<{ message: string; type: string; duration?: number }>) => {
            state.open = true;
            state.message = message;
            state.type = type;
            state.duration = duration;
        },

        closeNotify: (state) => {
            state.open = false;
        },

        openNotify: (state) => {
            state.open = true;
        },

        setMessageNotify: (state, { payload: { message } }: PayloadAction<{ message: string }>) => {
            state.message = message;
        },
	},
});

export const { 
    setNotify,
    closeNotify,
    openNotify,
    setMessageNotify,
 } = slice.actions;

export const selectNotify = (state: RootState) => state.notify;

export default slice.reducer;
