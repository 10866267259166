import { useEffect, useState } from 'react';
import { useColorScheme } from '@mui/joy/styles';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

export default function ColorSchemeToggle({ onClick, ...props }: IconButtonProps) {
	const { mode, setMode } = useColorScheme();
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return <IconButton size="sm" variant="plain" color="neutral" disabled />;
	}
	return (
		<IconButton
			id="toggle-mode"
			size="sm"
			variant="plain"
			color="neutral"
			{...props}
			onClick={event => {
				if (mode === 'light') {
					setMode('dark');
				} else {
					setMode('light');
				}
				onClick?.(event);
			}}>
			{mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
		</IconButton>
	);
}
