import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "../utils";

export const admin = createApi({
  baseQuery: customBaseQuery,
  reducerPath: "admin",
  endpoints: (builder) => ({
    getAvailableAllowance: builder.mutation<any, void>({
      query: () => ({
        url: "/available-allowance",
        method: "POST",
      }),
    }),
    makeTransfer: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "/execute-transfer",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetAvailableAllowanceMutation, useMakeTransferMutation } =
  admin;

export const { makeTransfer } = admin.endpoints;
