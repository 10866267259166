import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery, saveToCookies } from "../utils";

export const api = createApi({
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
        responseHandler(response: any) {
          if (response.ok) {
            saveToCookies("login", credentials.login);
            saveToCookies("password", credentials.password);

            return response;
          }
          return response.json().then((data: any) => {
            return data;
          });
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = api;
