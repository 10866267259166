import { Modal, ModalDialog, Typography } from '@mui/joy';

export const ModalTemplate = ({ open, setOpen, children, title, minWidth, minHeight }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; children?: React.ReactNode; title?: string; minWidth?: string; minHeight?: string }) => {
	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<ModalDialog aria-labelledby="basic-modal-dialog-title" aria-describedby="basic-modal-dialog-description" sx={{ minWidth: minWidth || '50%', maxWidth: '80%', minHeight: minHeight || 'auto', maxHeight: '80%', overflow: 'auto' }}>
				<Typography
					id="basic-modal-dialog-title"
					component="h2"
					sx={{
						marginBottom: '20px',
					}}>
					{title}
				</Typography>
				<Typography id="basic-modal-dialog-description" component="div">
					{children}
				</Typography>
			</ModalDialog>
		</Modal>
	);
};
